<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">Outstanding Issues</v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">Issues preventing orders from being sent.</v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col @click="Nav(item)" v-for="item in sortedItems" :key="item.Title" cols="6" class="d-flex align-center">
          <v-avatar rounded class="elevation-1" size="44" :color="resolveColor(item.Title).color">
            <v-icon dark color="white" size="30">
              {{ resolveColor(item.Title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ item.Title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ item.Total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiArchive,
  mdiArchiveOutline,
  mdiFaceAgent,
  // mdiCalendarToday
} from '@mdi/js'

import { ref, computed, onMounted, onUnmounted   } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  setup() {

    const { router  } = useRouter();

    const items = computed(() => {
      return store.state.app.DashboardIssues || [];
    });
    const sortedItems = computed(() => {
      return items.value.reverse();
    });
    const loading = ref(true);

    const fetchData = () => {
      store.dispatch('app/fetchIssuesDashboard')
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
    }
    fetchData();

    // fetch data periodically
    let intervalId;
    onMounted(() => {
      intervalId = setInterval(() => {
        fetchData();
      }, 56000); // every minute
    })
    onUnmounted(() => clearInterval(intervalId))


    const resolveColor = data => {
      if (data === 'Orders') return { icon: mdiArchive, color: 'error' }
      if (data === 'Issues') return { icon: mdiFaceAgent, color: 'success' }
      //if (data === 'Today') return { icon: mdiCalendarToday, color: 'success' }

      return { icon: mdiArchiveOutline, color: 'secondary' }
    }

    const Nav = (item) => {
      router.push({ name: 'order-customercare' });
    }

    return {
      sortedItems,
      Nav,
      resolveColor,
      items,
      loading
    }
  },
}
</script>
